import React, { HTMLAttributes } from 'react'
import cn from 'classnames'

import * as styles from './Wave.module.scss'

interface WaveProps extends HTMLAttributes<HTMLDivElement> {
  animationDelay?: string
  fullHeight?: boolean
}

export const Wave: React.FC<WaveProps> = ({
  className,
  children,
  animationDelay,
  fullHeight = false,
  style,
  ...props
}) => {
  return (
    <div
      className={cn(styles.root, fullHeight && styles.fullHeight, className)}
      style={{ ...style, animationDelay }}
      {...props}
    >
      {children}
    </div>
  )
}
