import dayjs from 'dayjs'

import { Language } from '../enum/Language.enum'

const dayjsLocales = {
  [Language.Ru]: () => import('dayjs/locale/ru'),
  [Language.En]: () => import('dayjs/locale/en'),
  [Language.Hi]: () => import('dayjs/locale/hi'),
  [Language.Vi]: () => import('dayjs/locale/vi'),
  [Language.Fr]: () => import('dayjs/locale/fr'),
  [Language.Ar]: () => import('dayjs/locale/ar'),
  [Language.Es]: () => import('dayjs/locale/es'),
  [Language.Ko]: () => import('dayjs/locale/ko'),
  [Language.Ja]: () => import('dayjs/locale/ja'),
  [Language.Bn]: () => import('dayjs/locale/bn'),
  [Language.Cn]: () => import('dayjs/locale/zh-cn'),
  [Language.Pt]: () => import('dayjs/locale/pt'),
  [Language.Id]: () => import('dayjs/locale/id'),
  [Language.It]: () => import('dayjs/locale/it-ch'),
}

export const setDayjsLocale = async (languageCode: keyof typeof dayjsLocales) => {
  await dayjsLocales[languageCode || Language.Default]().then(() => dayjs.locale(languageCode))
}
