import React from 'react'

import Ae from '@assets/images/flags/ae.inline.svg'
import Al from '@assets/images/flags/al.inline.svg'
import Am from '@assets/images/flags/am.inline.svg'
import At from '@assets/images/flags/at.inline.svg'
import Au from '@assets/images/flags/au.inline.svg'
import Az from '@assets/images/flags/az.inline.svg'
import Ba from '@assets/images/flags/ba.inline.svg'
import Be from '@assets/images/flags/be.inline.svg'
import Bg from '@assets/images/flags/bg.inline.svg'
import Br from '@assets/images/flags/br.inline.svg'
import Bt from '@assets/images/flags/bt.inline.svg'
import Bh from '@assets/images/flags/bh.inline.svg'
import By from '@assets/images/flags/by.inline.svg'
import Bj from '@assets/images/flags/bj.inline.svg'
import Ca from '@assets/images/flags/ca.inline.svg'
import Ch from '@assets/images/flags/ch.inline.svg'
import Cm from '@assets/images/flags/cm.inline.svg'
import Cn from '@assets/images/flags/cn.inline.svg'
import Cl from '@assets/images/flags/cl.inline.svg'
import Co from '@assets/images/flags/co.inline.svg'
import Cy from '@assets/images/flags/cy.inline.svg'
import Cz from '@assets/images/flags/cz.inline.svg'
import Ci from '@assets/images/flags/ci.inline.svg'
import De from '@assets/images/flags/de.inline.svg'
import Dk from '@assets/images/flags/dk.inline.svg'
import Dz from '@assets/images/flags/dz.inline.svg'
import Ee from '@assets/images/flags/ee.inline.svg'
import Eg from '@assets/images/flags/eg.inline.svg'
import Es from '@assets/images/flags/es.inline.svg'
import Gb from '@assets/images/flags/gb.inline.svg'
import Ge from '@assets/images/flags/ge.inline.svg'
import Gh from '@assets/images/flags/gh.inline.svg'
import Gn from '@assets/images/flags/gn.inline.svg'
import Gr from '@assets/images/flags/gr.inline.svg'
import Hr from '@assets/images/flags/hr.inline.svg'
import Hu from '@assets/images/flags/hu.inline.svg'
import Id from '@assets/images/flags/id.inline.svg'
import Ie from '@assets/images/flags/ie.inline.svg'
import Il from '@assets/images/flags/il.inline.svg'
import In from '@assets/images/flags/in.inline.svg'
import Is from '@assets/images/flags/is.inline.svg'
import It from '@assets/images/flags/it.inline.svg'
import Ir from '@assets/images/flags/ir.inline.svg'
import Fi from '@assets/images/flags/fi.inline.svg'
import Fr from '@assets/images/flags/fr.inline.svg'
import Jp from '@assets/images/flags/jp.inline.svg'
import Kg from '@assets/images/flags/kg.inline.svg'
import Kh from '@assets/images/flags/kh.inline.svg'
import Kr from '@assets/images/flags/kr.inline.svg'
import Kw from '@assets/images/flags/kw.inline.svg'
import Kz from '@assets/images/flags/kz.inline.svg'
import La from '@assets/images/flags/la.inline.svg'
import Li from '@assets/images/flags/li.inline.svg'
import Lt from '@assets/images/flags/lt.inline.svg'
import Lu from '@assets/images/flags/lu.inline.svg'
import Ly from '@assets/images/flags/ly.inline.svg'
import Lv from '@assets/images/flags/lv.inline.svg'
import Ma from '@assets/images/flags/ma.inline.svg'
import Mc from '@assets/images/flags/mc.inline.svg'
import Md from '@assets/images/flags/md.inline.svg'
import Me from '@assets/images/flags/me.inline.svg'
import Mk from '@assets/images/flags/mk.inline.svg'
import Mt from '@assets/images/flags/mt.inline.svg'
import Mx from '@assets/images/flags/mx.inline.svg'
import My from '@assets/images/flags/my.inline.svg'
import Ml from '@assets/images/flags/ml.inline.svg'
import Na from '@assets/images/flags/na.inline.svg'
import Ng from '@assets/images/flags/ng.inline.svg'
import Nl from '@assets/images/flags/nl.inline.svg'
import No from '@assets/images/flags/no.inline.svg'
import Np from '@assets/images/flags/np.inline.svg'
import Nz from '@assets/images/flags/nz.inline.svg'
import Om from '@assets/images/flags/om.inline.svg'
import Pe from '@assets/images/flags/pe.inline.svg'
import Ph from '@assets/images/flags/ph.inline.svg'
import Pl from '@assets/images/flags/pl.inline.svg'
import Pt from '@assets/images/flags/pt.inline.svg'
import Qa from '@assets/images/flags/qa.inline.svg'
import Ro from '@assets/images/flags/ro.inline.svg'
import Rs from '@assets/images/flags/rs.inline.svg'
import Ru from '@assets/images/flags/ru.inline.svg'
import Sa from '@assets/images/flags/sa.inline.svg'
import Se from '@assets/images/flags/se.inline.svg'
import Sg from '@assets/images/flags/sg.inline.svg'
import Si from '@assets/images/flags/si.inline.svg'
import Sk from '@assets/images/flags/sk.inline.svg'
import Sm from '@assets/images/flags/sm.inline.svg'
import Sn from '@assets/images/flags/sn.inline.svg'
import Th from '@assets/images/flags/th.inline.svg'
import Tj from '@assets/images/flags/tj.inline.svg'
import Tm from '@assets/images/flags/tm.inline.svg'
import Tn from '@assets/images/flags/tn.inline.svg'
import Tr from '@assets/images/flags/tr.inline.svg'
import Us from '@assets/images/flags/us.inline.svg'
import Uz from '@assets/images/flags/uz.inline.svg'
import Vn from '@assets/images/flags/vn.inline.svg'
import Za from '@assets/images/flags/za.inline.svg'

const flags = {
  ae: Ae,
  al: Al,
  am: Am,
  at: At,
  au: Au,
  az: Az,
  ba: Ba,
  be: Be,
  bg: Bg,
  br: Br,
  bt: Bt,
  bh: Bh,
  by: By,
  bj: Bj,
  ca: Ca,
  ch: Ch,
  cm: Cm,
  cn: Cn,
  cl: Cl,
  co: Co,
  cy: Cy,
  cz: Cz,
  ci: Ci,
  de: De,
  dk: Dk,
  dz: Dz,
  ee: Ee,
  eg: Eg,
  es: Es,
  gb: Gb,
  ge: Ge,
  gh: Gh,
  gn: Gn,
  gr: Gr,
  hr: Hr,
  hu: Hu,
  id: Id,
  ie: Ie,
  il: Il,
  in: In,
  is: Is,
  it: It,
  ir: Ir,
  fi: Fi,
  fr: Fr,
  jp: Jp,
  kg: Kg,
  kh: Kh,
  kr: Kr,
  kw: Kw,
  kz: Kz,
  la: La,
  li: Li,
  lt: Lt,
  lu: Lu,
  ly: Ly,
  lv: Lv,
  ma: Ma,
  mc: Mc,
  md: Md,
  me: Me,
  mk: Mk,
  mt: Mt,
  mx: Mx,
  my: My,
  ml: Ml,
  na: Na,
  ng: Ng,
  nl: Nl,
  no: No,
  np: Np,
  nz: Nz,
  om: Om,
  pe: Pe,
  ph: Ph,
  pl: Pl,
  pt: Pt,
  qa: Qa,
  ro: Ro,
  rs: Rs,
  ru: Ru,
  sa: Sa,
  se: Se,
  sg: Sg,
  si: Si,
  sk: Sk,
  sm: Sm,
  sn: Sn,
  th: Th,
  tj: Tj,
  tm: Tm,
  tn: Tn,
  tr: Tr,
  us: Us,
  uz: Uz,
  vn: Vn,
  za: Za,
}

interface FlagProps extends React.SVGAttributes<SVGAElement> {
  country: string
}

/**
 * @param country - Country code ALPHA-2 in lowercase
 */
export const Flag: React.FC<FlagProps> = ({ country, ...props }) => {
  const Component = flags[country as keyof typeof flags] || (() => null)
  return <Component {...props} />
}
