/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import cn from 'classnames'

import * as styles from './EmbedVideo.module.scss'

import { Loader } from '../Loader'

interface EmbedVideoProps extends React.IframeHTMLAttributes<HTMLIFrameElement> {
  youtubeID?: string
  autoplay?: boolean
  relatedVideos?: boolean
  controls?: boolean
  showInfo?: boolean
  loop?: boolean
}

export const EmbedVideo: React.FC<EmbedVideoProps> = ({
  youtubeID,
  src,
  title,
  autoplay,
  className,
  relatedVideos = false,
  controls = false,
  showInfo = false,
  loop = true,
  ...props
}) => {
  const [loading, setLoading] = useState(true)

  const youtubeSrc = `https://www.youtube.com/embed/${youtubeID}?rel=${Number(relatedVideos)}&controls=${Number(controls)}&showinfo=${showInfo}&playlist=${youtubeID}&loop=${loop}&autoplay=${Number(autoplay)}&mute=0`

  return (
    <div className={styles.root}>
      <Loader className={styles.loader} />
      <iframe
        className={cn(styles.iframe, className)}
        src={youtubeID ? youtubeSrc : src}
        title={title}
        frameBorder="0"
        allow="autoplay; encrypted-media"
        scrolling="no"
        allowFullScreen
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        {...props}
        onLoad={() => setLoading(false)}
      />
    </div>
  )
}
