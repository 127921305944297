import { useContext } from 'react'
import { AppContext } from '@components/App'
import { Language } from '../enum/Language.enum'

export interface Content {
    content: 'string'
    language: 'string'
}

export const getContent = (content?: Content[]) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { langCode } = useContext(AppContext)
  const result = content?.find(({ language }) => language.toLowerCase() === langCode?.toLowerCase())
  const resultEn = content?.find(({ language }) => language.toLowerCase() === Language.Default)
  return result?.content || resultEn?.content || ''
}
