import React from 'react'

import cn from 'classnames'

import ArrowIcon from '../../../../assets/images/weter/arrowSelect.inline.svg'

import * as styles from './Select.module.scss'

export interface SelectOption {
  value: string | number
  label?: string
  img?: string
}

type Click = MouseEvent & {
  path: Node[]
}

interface SelectProps {
  variant?: 'transparent' | 'fill'
  color?: 'blue' | 'green'
  selected: SelectOption['value']
  options: SelectOption[]
  setSelected: (id: SelectOption['value']) => void
  renderOption?: (params: SelectOption) => React.ReactNode
}

export const Select: React.FC<SelectProps> = ({
  variant = 'transparent',
  color = 'blue',
  selected,
  options = [],
  renderOption,
  setSelected,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const selectRef = React.useRef(null)

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // eslint-disable-next-line no-underscore-dangle
      const _event = event as Click

      if (selectRef.current && !_event.composedPath().includes(selectRef.current)) {
        setIsOpen(false)
      }
    }

    document.body.addEventListener('click', handleClickOutside)

    return () => document.body.removeEventListener('click', handleClickOutside)
  }, [])

  return (
    <div
      className={cn(styles.root, styles[variant], styles[color])}
      onKeyDown={() => setIsOpen((prev) => !prev)}
      onClick={() => setIsOpen((prev) => !prev)}
      role="button"
      tabIndex={0}
      ref={selectRef}
    >
      <div className={styles.selected}>
        {renderOption ? renderOption(options.find(({ value }) => selected === value) as SelectOption) : selected}
      </div>
      <div className={cn(styles.iconWrap, isOpen && styles.open)}>
        <ArrowIcon />
      </div>
      {isOpen && (
        <div className={styles.list}>
          {options.length &&
            options.map((props) => (
              <div
                key={props.value}
                className={styles.itemMenu}
                onKeyDown={() => setSelected(props.value)}
                onClick={() => setSelected(props.value)}
                role="button"
                tabIndex={0}
              >
                {renderOption ? renderOption(props) : props.label}
              </div>
            ))}
        </div>
      )}
    </div>
  )
}
