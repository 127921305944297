export const committeeList = [
  {
    name: 'Henrik Christiansen',
    img: 'HenrikChristiansen',
    country: 'ae',
    socialMedia: [
      {
        type: 'instagram',
        url: 'https://instagram.com/hc.halager?igshid=131wq1xj8awn5',
      },
      {
        type: 'facebook',
        url: 'https://facebook.com/ibolya.voros.988',
      },
    ],
  },
  {
    name: 'Chandra Wati Sharma',
    img: 'ChandraWatiSharma',
    country: 'bt',
    socialMedia: [
      {
        type: 'facebook',
        url: 'https://facebook.com/profile.php?id=100056422953934',
      },
    ],
  },
  {
    name: 'John Espinel',
    img: 'JohnEspinel',
    country: 'co',
    socialMedia: [
      {
        type: 'facebook',
        url: 'https://facebook.com/john.espinel',
      },
    ],
  },
  {
    name: 'Alex Bendecido',
    img: 'AlexBendecido',
    country: 'es',
    socialMedia: [
      {
        type: 'instagram',
        url: 'https://www.instagram.com/alexbendecidoinvest/?r=nametag',
      },
    ],
  },
  {
    name: 'Dominique Ntoutoume',
    img: 'DominiqueNtoutoume',
    country: 'gn',
    socialMedia: [
      {
        type: 'telegram',
        url: 'https://t.me/Dominique_WebEntrepreneur',
      },
    ],
  },
  {
    name: 'H. Nasrulloh',
    img: 'Nasrulloh',
    country: 'id',
    socialMedia: [
      {
        type: 'facebook',
        url: 'https://www.facebook.com/profile.php?id=100012422730899',
      },
    ],
  },
  {
    name: 'Mattia Magurno',
    img: 'MattiaMagurno',
    country: 'it',
    socialMedia: [
      {
        type: 'instagram',
        url: 'https://www.instagram.com/mattx_1/',
      },
    ],
  },
  {
    name: 'Artid Fejzo',
    img: 'ArtidFejzo',
    country: 'it',
    socialMedia: [
      {
        type: 'instagram',
        url: 'https://instagram.com/invites/contact?i=2wodsore6fsr',
      },
    ],
  },
  {
    name: 'Sung Won Kim',
    img: 'SungWonKim',
    country: 'kr',
    socialMedia: [
      {
        type: 'facebook',
        url: 'https://www.facebook.com/swkim.rtbkorea',
      },
    ],
  },
  {
    name: 'Aigul Ualieva',
    img: 'AigulUalieva',
    country: 'kz',
    socialMedia: [
      {
        type: 'instagram',
        url: 'https://instagram.com/moon_flower78_',
      },
    ],
  },
  {
    name: 'Chandra Bhanu Pratap',
    img: 'ChandraBhanu',
    country: 'us',
    socialMedia: [
      {
        type: 'instagram',
        url: 'https://instagram.com/swamdogmillionaire?r=nametag',
      },
    ],
  },
  {
    name: 'Dragan Mirilo',
    img: 'DraganMirilo',
    country: 'rs',
    socialMedia: [
      {
        type: 'instagram',
        url: 'https://www.instagram.com/dr_mirilo/',
      },
    ],
  },
  {
    name: 'Maxim Sergeev',
    img: 'MaximSergeev',
    country: 'ae',
    socialMedia: [
      {
        type: 'instagram',
        url: 'https://instagram.com/maxim_sergeev',
      },
    ],
  },
]
