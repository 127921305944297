// Улучшить
// export const getRandom = (arr: Array<unknown>, n: number) => {
//   let result = new Array(n)
//   let len = arr.length
//   let taken = new Array(len)
//   if (n > len) {
//     throw new RangeError('getRandom: more elements taken than available')
//   }
//   while (n--) {
//     let x = Math.floor(Math.random() * len)
//     result[n] = arr[x in taken ? taken[x] : x]
//     taken[x] = --len in taken ? taken[len] : len
//   }
//   return result
// }

export const priorityCountries = [
  // 'in',
  // 'ru',
  'es',
  'pe',
  'gb',
  // 'vn',
  'it',
  // 'by',
  'be',
  'kr',
  // 'np',
  'id',
  'us',
  'za',
  'co',
  'na',
  'ng',
  'ae',
  'de',
  'mx',
  'kz',
  'gn',
  'md',
  'rs',
  'bg',
  'gh',
  'cm',
  'bt',
  'hu',
]

export const getRandomElements = <T>(arr: Array<T>, count: number) =>
  [...arr].sort(() => 0.5 - Math.random()).slice(0, count)
