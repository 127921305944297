import React, { HTMLAttributes } from 'react'
import cx from 'classnames'

import * as styles from './List.module.scss'

interface Option {
  value: number | string
  label: React.ReactNode
}

interface ListProps extends HTMLAttributes<HTMLUListElement> {
  options: Option[]
  value: number | string
}

export const List: React.FC<ListProps> = ({ options, value, className, ...props }) => {
  return (
    <ul className={cx(styles.root, className)} {...props}>
      {options?.map((el) => (
        <li
          className={cx(styles.item, el.value === value && styles.selected)}
          key={el.value}
        >
          {el.label}
        </li>
      ))}
    </ul>
  )
}
