import React, { useContext, useEffect, useState } from 'react'
import cn from 'classnames'

import * as styles from './LanguageSelect.module.scss'

import { useLanguages } from '@graphql'
import { AppContext } from '@components/App'
import { List, Link, Img, Typography } from '@UIKit'
import { replaceLink } from '@utils'

interface LanguageSelectProps {
  className?: string
}

export const LanguageSelect: React.FC<LanguageSelectProps> = ({ className }) => {
  const allLanguages = useLanguages()
  const { langCode } = useContext(AppContext)
  const [filteredLanguages, setFilteredLanguages] = useState<typeof allLanguages>([])

  useEffect(() => {
    const languages = window.location.hostname.includes('voda')
      ? allLanguages.filter(({ code }) => ['ru', 'en'].includes(code))
      : allLanguages

    setFilteredLanguages(languages)
  }, [])
  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.button}>{langCode}</div>

      <List
        className={styles.list}
        value={langCode}
        options={filteredLanguages.map(({ icon, code, language }) => ({
          value: code,
          label: (
            <Link key={code} to={() => replaceLink('/{code}', code)} className={styles.listItem}>
              <Img className={styles.flag} src={icon} />
              <Typography disableLocalize size={16}>
                {language}
              </Typography>
            </Link>
          ),
        }))}
      />
    </div>
  )
}
