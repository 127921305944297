import { useStaticQuery, graphql } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'

import { committeeList } from '@components/sections/Committee/CommitteeList'
import { CommitteeMember } from './committee'

export const useCommitteeLegacy = (): CommitteeMember[] => {
  const data: Record<string, { id: string; imageData: IGatsbyImageData }> = useStaticQuery<{
    allFile: { nodes: Array<{ id: string; name: string; childImageSharp: { gatsbyImageData: IGatsbyImageData } }> }
  }>(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "committee" } }) {
        nodes {
          id
          name
          childImageSharp {
            gatsbyImageData(width: 170, height: 170, placeholder: BLURRED, quality: 100)
          }
        }
      }
    }
  `).allFile.nodes.reduce(
    (acc, { id, name, childImageSharp }) => ({
      ...acc,
      [name]: { id, imageData: childImageSharp.gatsbyImageData },
    }),
    {},
  )

  return committeeList.map((committee: any) => ({
    ...committee,
    id: data[committee.img].id,
    image: data[committee.img].imageData,
    city: '',
  }))
}
