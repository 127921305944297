/* eslint-disable max-len */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import { useStaticQuery, graphql } from 'gatsby'
import { useLanguages } from './languages'

const getRawTranslations = (): Record<string, any> => {
  return useStaticQuery(graphql`
    {
      ru {
        internal {
          content
        }
      }
      en {
        internal {
          content
        }
      }
      vi {
        internal {
          content
        }
      }
      hi {
        internal {
          content
        }
      }
      fr {
        internal {
          content
        }
      }
      ar {
        internal {
          content
        }
      }
      es {
        internal {
          content
        }
      }
      ko {
        internal {
          content
        }
      }
      ja {
        internal {
          content
        }
      }
      bn {
        internal {
          content
        }
      }
      cn {
        internal {
          content
        }
      }
      pt {
        internal {
          content
        }
      }
      id {
        internal {
          content
        }
      }
      it {
        internal {
          content
        }
      }
    }
  `)
}

const getContent = (code: string) => {
  const content = getRawTranslations()[code]?.internal?.content

  if (content) {
    try {
      return JSON.parse(content)
    } catch (error) {
      console.error(`Error parsing content for language ${code}:`, error)
    }
  }

  return null
}

export type Translations = Record<string, Record<string, string>>

export const getTranslations = (): Translations => {
  const lang = useLanguages()
  const result: Translations = {}

  lang.map(({ code }) => {
    result[code] = getContent(code)
  })

  return result
}
