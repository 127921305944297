/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import cn from 'classnames'

import * as styles from './Tabs.module.scss'

import { useInterval } from '@hooks'
import { Typography } from '../Typography'

const delay = 2000

interface Tab {
  tab: React.ReactNode
  content: React.ReactNode
}

interface TabsProps {
  autoplay?: boolean
  className?: string
  tabs: Tab[]
}

export const Tabs: React.FC<TabsProps> = ({ autoplay = false, className, tabs }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [isPlaying, setPlaying] = useState<boolean>(true)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleTab = async (index: number) => {
    await setPlaying(false)
    setCurrentIndex(index)
    setPlaying(true)
  }

  useInterval(
    () => setCurrentIndex((state) => (state + 1) % tabs.length),
    // Delay in milliseconds or null to stop it
    autoplay && isPlaying ? delay : null,
  )

  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.tabs}>
        {tabs.map(({ tab }, index) => (
          <button
            key={index}
            className={cn(styles.tab, tabs[currentIndex].tab === tab && styles.active)}
            onClick={() => handleTab(index)}
          >
            <Typography size={20} color="default" disableLocalize>
              {tab}
            </Typography>
          </button>
        ))}
      </div>
      <div className={styles.content} onMouseEnter={() => setPlaying(false)} onMouseLeave={() => setPlaying(true)}>
        {tabs.map(({ content }, index) => (
          <div key={index} className={cn(styles.tabContent, currentIndex === index && styles.active)}>
            {content}
          </div>
        ))}
      </div>
    </div>
  )
}
