import React, { useContext } from 'react'
import { Link as LinkGatsby, GatsbyLinkProps } from 'gatsby'
import cn from 'classnames'

import { replaceLink } from '@utils'
import { AppContext } from '@components/App'

import * as styles from './Link.module.scss'

// eslint-disable-next-line @typescript-eslint/ban-types
export interface LinkProps<T = {}> extends Omit<GatsbyLinkProps<T>, 'to' | 'ref'> {
  to: string | ((code: string) => string)
  allowEn?: boolean
  color?: 'default' | 'primary'
  className?: string
}

const isExternalLink = (path: string) =>
  path?.startsWith('http://') ||
    path?.startsWith('https://') ||
    path?.startsWith('//') ||
    path?.startsWith('mailto') ||
    path?.startsWith('tel')

export const Link = <T, >({
  to,
  allowEn,
  className,
  color = 'default',
  children,
  ...props
}: LinkProps<T>) => {
  const { langCode } = useContext(AppContext)

  const toCurrent = typeof to === 'function'
    ? to(langCode)
    : replaceLink(to, langCode, allowEn)

  if (props.target === '_blank') {
    return (
      <a
        {...props}
        href={toCurrent}
        rel="noopener noreferrer"
        target="_blank"
        className={cn(styles.root, styles[color], className)}
      >
        {children}
      </a>
    )
  }

  if (isExternalLink(toCurrent)) {
    return (
      <a
        {...props}
        href={toCurrent}
        className={cn(styles.root, styles[color], className)}
      >
        {children}
      </a>
    )
  }

  return (
    <LinkGatsby
      {...props}
      to={toCurrent}
      className={cn(styles.root, styles[color], className)}
    >
      {children}
    </LinkGatsby>
  )
}
