import React, { HTMLAttributes } from 'react'
import { Translate } from 'react-localize-alias'
import cn from 'classnames'

import * as styles from './Typography.module.scss'

interface TypographyProps extends HTMLAttributes<HTMLParagraphElement> {
  size?: 12 | 16 | 18 | 20 | 24 | 32 | 48 | 64 | 152
  weight?: 400 | 500 | 700 | 900
  color?: 'default' | 'white' | 'inherit'
  disableLocalize?: boolean
  missOnAlias?: boolean
}

export const Typography: React.FC<TypographyProps> = ({
  size = 16,
  weight = 400,
  color = 'default',
  children,
  className,
  disableLocalize,
  missOnAlias,
  ...props
}) => {
  const isNumberOrString = typeof children === 'string' || typeof children === 'number'

  return (
    <p className={cn(styles[`${color}Color`], styles[`size${size}`], styles[`weight${weight}`], className)} {...props}>
      {isNumberOrString && !disableLocalize ? <Translate id={String(children)} missOnAlias={missOnAlias} /> : children}
    </p>
  )
}
