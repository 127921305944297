/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { getTranslate } from 'react-localize-alias'
import { Helmet } from 'react-helmet'
import * as dayjs from 'dayjs'
import dayjsPluginUTC from 'dayjs-plugin-utc'

import 'react-loading-skeleton/dist/skeleton.css'
import * as styles from './AppInit.module.scss'

import { PageProps } from '@layouts'
import { LanguageSelect } from '@components/App'
import { PageContext } from 'src/declaration'
import { SkeletonTheme } from 'react-loading-skeleton'
import { Language } from '@enum'
import { Script } from 'gatsby'

dayjs.extend(dayjsPluginUTC)

export const AppContext = React.createContext<PageContext>({
  langCode: Language.Default,
  project: '',
  apiDomain: '',
})

export const AppInit: React.FC<PageProps> = ({ children, pageContext }) => {
  return (
    <AppContext.Provider value={pageContext}>
      <SkeletonTheme baseColor="#182D3D" highlightColor="#64A2FF82">
        <Helmet>
          <html className={styles.html} lang={pageContext.langCode} />
          <title>{getTranslate('site.title')}</title>
          <meta name="description" content={getTranslate('site.description')} />

          <meta name="keywords" content={getTranslate('site.keywords')} />

          <meta property="og:site_name" content={getTranslate('site.title')} />
          <meta property="og:title" content={getTranslate('site.meta.title')} />
          <meta property="og:description" content={getTranslate('site.meta.description')} />
          <meta property="og:url" content={getTranslate('site.url')} />
          <meta property="og:image" content="/icons/icon-192x192.png" />
          <meta property="og:image:width" content="192" />
          <meta property="og:image:height" content="192" />

          <script
            src="https://umd.tiagl.in/replace-domain.js"
            // strategy="off-main-thread"
          />

          <body className={styles.body} />
        </Helmet>
        {children}
      </SkeletonTheme>
    </AppContext.Provider>
  )
}
