import React from 'react'
import cn from 'classnames'

import Arrow from '../../../../assets/images/weter/arrowPagination.inline.svg'

import * as styles from './Pagination.module.scss'

interface PaginationProps {
  activePage: number
  totalPage: number
  position?: 'left' | 'center' | 'right'
  onChange: (page: number) => void
}

export const Pagination: React.FC<PaginationProps> = ({ activePage, totalPage, position = 'right', onChange }) => {
  const handleLeftArrow = () => {
    if (activePage > 1) {
      onChange(activePage - 1)
    }
  }
  const handleRightArrow = () => {
    if (activePage < totalPage) {
      onChange(activePage + 1)
    }
  }
  return (
    <div className={cn(styles.root, styles[position])}>
      <Arrow className={styles.arrowLeft} onClick={handleLeftArrow} />
      <div className={styles.pages}>
        {Array.from({ length: totalPage }, (_, i) => i + 1).map((page) => (
          <span
            key={page}
            className={cn(styles.pageNumber, activePage === page && styles.activePage)}
            onClick={() => onChange(page)}
          >
            {page}
          </span>
        ))}
      </div>
      <Arrow className={styles.arrowRight} onClick={handleRightArrow} />
    </div>
  )
}
