import { useStaticQuery, graphql } from 'gatsby'

export interface Language {
  id: number
  code: string
  language: string
  displayName: string
  icon: string
  active: boolean
}

export const useLanguages = (): Language[] => {
  return useStaticQuery(graphql`
    {
      languages {
        data {
          active
          code
          countries
          displayName
          icon
          id
          language
        }
      }
    }
  `).languages.data
}
