export enum Language {
  Ru = 'ru',
  En = 'en',
  Hi = 'hi',
  Vi = 'vi',
  Ar = 'ar',
  Fr = 'fr',
  Es = 'es',
  Ko = 'ko',
  Ja = 'ja',
  Bn = 'bn',
  Cn = 'cn',
  Pt = 'pt',
  Id = 'id',
  It = 'it',
  Default = 'en',
}
