/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
import React, { HTMLAttributes } from 'react'
import cn from 'classnames'

import * as styles from './Hexagon.module.scss'

import HexagonStroke from '../hexagonStroke.inline.svg'
import HexagonFill from '../hexagonFill.inline.svg'

interface HexagonProps extends HTMLAttributes<HTMLDivElement> {
  border?: boolean
  color?: 'blue' | 'dark' | 'transparent'
  cursor?: 'default' | 'pointer'
  size?: number
}

export const Hexagon: React.FC<HexagonProps> = ({
  border = false,
  color = 'blue',
  cursor = 'default',
  className,
  children,
  size,
  style,
  ...props
}) => {
  return (
    <div
      className={cn(styles.root, styles[cursor], styles[color], className)}
      style={{ width: size, height: size, ...style }}
      {...props}
    >
      {border && <HexagonStroke className={styles.border} />}
      <HexagonFill className={cn(styles.hexagon, !border && styles.fullSize)} />

      <div className={styles.content}>{children}</div>
    </div>
  )
}
