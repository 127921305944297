// extracted by mini-css-extract-plugin
export var whiteColor = "Typography-module--whiteColor--abf6d";
export var defaultColor = "Typography-module--defaultColor--b5a41";
export var inheritColor = "Typography-module--inheritColor--20e33";
export var weight400 = "Typography-module--weight400--8e7e7";
export var weight500 = "Typography-module--weight500--3f84f";
export var weight700 = "Typography-module--weight700--922eb";
export var weight900 = "Typography-module--weight900--0f184";
export var size12 = "Typography-module--size12--60ef4";
export var size16 = "Typography-module--size16--17902";
export var size18 = "Typography-module--size18--c7708";
export var size20 = "Typography-module--size20--c3f84";
export var size24 = "Typography-module--size24--2b1ac";
export var size32 = "Typography-module--size32--db176";
export var size48 = "Typography-module--size48--e11cc";
export var size64 = "Typography-module--size64--e22c2";
export var size152 = "Typography-module--size152--ee88d";