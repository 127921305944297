import React from 'react'
import cn from 'classnames'

import * as styles from './Loader.module.scss'

interface LoaderProps {
  className: string
}

export const Loader: React.FC<LoaderProps> = ({ className }) => <span className={cn(styles.root, className)} />
