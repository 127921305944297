/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { Swiper, SwiperSlide, SwiperProps } from 'swiper/react'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import cn from 'classnames'

import * as styles from './Carousel.module.scss'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'

interface CarouselProps extends Omit<SwiperProps, 'onClick'> {
  slides: React.ReactNode[]
  onClick?: (slide: React.ReactNode, index: number) => void
}

export const Carousel: React.FC<CarouselProps> = ({ slides, className, onClick = () => {}, ...props }) => {
  return (
    <Swiper
      centeredSlides
      spaceBetween={5}
      loop
      modules={[Pagination, Navigation, Autoplay]}
      className={cn(styles.root, className)}
      {...props}
    >
      {slides.map((slide, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <SwiperSlide key={index} onClick={() => onClick(slide, index)}>
          {slide}
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
