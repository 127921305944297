import React, { HTMLAttributes } from 'react'

import cn from 'classnames'

import * as styles from './HexagonImage.module.scss'

interface HexagonImageProps {
  className?: string,
  withoutborder?: boolean,
  children?: HTMLAttributes<HTMLDivElement>,
}

export const HexagonImage:React.FC<HexagonImageProps> = ({ className, withoutborder = false, children }) => (
  <div className={cn(styles.root, withoutborder && styles.withoutborder, className)}>{children}</div>
)
