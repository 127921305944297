import React from 'react'

import cn from 'classnames'

import * as styles from './Button.module.scss'

type ButtonDefault = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

export interface ButtonProps extends ButtonDefault {
  variant?: 'primary' | 'secondary' | 'light'
  fullWidth?: boolean
  suffix?: JSX.Element | string
  suffixPosition?: 'right' | 'left'
  disabled?: boolean
  className?: string
}

export const Button: React.FC<ButtonProps> = ({
  variant = 'primary',
  fullWidth = false,
  suffix,
  suffixPosition = 'left',
  disabled,
  className,
  children,
  ...props
}) => {
  return (
    <button
      disabled={disabled}
      className={cn(styles.root, styles[variant], fullWidth && styles.fullWidth, className)}
      {...props}
    >
      {suffix && (
        <div
          className={cn(
            styles.suffix,
            suffixPosition === 'left' && styles.suffixL,
            suffixPosition === 'right' && styles.suffixR,
          )}
        >
          {suffix}
        </div>
      )}
      {children}
    </button>
  )
}
