import React from 'react'

import cn from 'classnames'

import * as styles from './Badge.module.scss'

interface BadgeProps {
  className: string,
}

export const Badge: React.FC<BadgeProps> = ({ className, children, ...props }) => (
  <div className={cn(styles.root, className)} {...props}>
    {children}
  </div>
)
