import { Language } from '@enum'

const clearRepeat = (text: string) => {
  return text.replace(/([^:])(\/)+/g, '$1/')
}

export const replaceLink = (to: string, code: string, allowEn?: boolean) => {
  if (!allowEn && code === Language.Default) {
    return clearRepeat(
      to
        .replace(`/${Language.Default}`, '/')
        .replace(/\{(code)\}/g, ''),
    )
  }

  return clearRepeat(to.replace(/\{(code)\}/g, code))
}
