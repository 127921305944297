import { useStaticQuery, graphql } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'

export interface CommitteeMember {
  id: string
  name: string
  image: IGatsbyImageData
  country: string
  city: string
  socialMedia: {
    type: string
    url: string
  }[]
}

export const useCommittee = (): CommitteeMember[] => {
  const data = useStaticQuery<{
    allCommittee: {
      nodes: Array<{
        id: string
        name: string
        image: {
          childImageSharp: { gatsbyImageData: IGatsbyImageData }
        }
        country: string
        city: string
        socialMedia: {
          type: string
          url: string
        }[]
      }>
    }
  }>(graphql`
    {
      allCommittee {
        nodes {
          country
          city
          name
          socialMedia {
            type
            url
          }
          image {
            id
            childImageSharp {
              gatsbyImageData(width: 300, height: 300, placeholder: BLURRED, quality: 100)
            }
          }
        }
      }
    }
  `).allCommittee.nodes.map(({ image, ...rest }) => ({ ...rest, image: image.childImageSharp.gatsbyImageData }))

  return data
}
