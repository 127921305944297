import React, { forwardRef } from 'react'
import {
  Img as ReactImg,
  ImgProps as ImgPropsLib,
} from 'react-image'
import Skeleton from 'react-loading-skeleton'

import BrokenIcon from '@assets/images/broken.inline.svg'

export type ImgProps = Omit<ImgPropsLib, 'unloader' | 'loader'>

export const Img = forwardRef<HTMLImageElement, ImgProps>(({
  style,
  className,
  ...props
}, ref) => {
  return (
    <ReactImg
      {...props}
      ref={ref}
      unloader={<BrokenIcon className={className} style={style} />}
      loader={<Skeleton className={className} containerClassName={className} style={style} />}
      loading="lazy"
      className={className}
      style={style}
    />
  )
})
